const Colors = {
background:'#f2f2f2',
primary :'#2b486c',
clear:'#ffffff',
semiclear:'#fdfdfd',
inactive:'#d3d3d3',
grayText:'#868686',
black:'#606060',
blueText2:'#23306c',
blueText:'#2b486c',
red:'#FF4E4E',
orange:'#FF8600',
}

export default Colors 